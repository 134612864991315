class ZIDXAccountListingIndexEdit implements ZIDXPage{
    constructor() {
    }
    getPageClass(){
        return "zidxAccountListingIndexEditContainer";
    }
    getMatchURL(){
        return "/account/data-feeds/listing-index/edit";
    }
    render(){
        // console.log("listing index edit");
    }
}